import { Literal, Static, Union } from 'runtypes';
import { EmployeeRole } from '../types/employee-role';

export const UserRole = Union(EmployeeRole, Literal('superAdmin'), Literal('miniManager'));

export type UserRole = Static<typeof UserRole>;

export const userHasMenuItemRole = (userRoles: string[], menuItemRoles: string[]) => {
    if (!menuItemRoles.length) {
        return true;
    }

    if (!menuItemRoles.some((role) => userRoles.includes(role))) {
        return false;
    }

    return true;
};

export const getUserRoles = (isSuperAdmin: number, institution: AppState['institution']) => {
    if (!institution) {
        return [];
    }
    const userRole = institution.employeeRole;

    const userRoles = [userRole];

    if (isSuperAdmin === 1) {
        userRoles.push('superAdmin');
    }

    if (institution!.isMiniManager === 1) {
        userRoles.push('miniManager');
    }

    return userRoles;
};

export const level1Users = ['pedagogue', 'pedagogicalAssistant', 'substitute'];
export const level2Users = ['manager', 'miniManager'];
export const level3Users = ['superAdmin'];

export const BUPL_ROLES = ['manager', 'pedagogue', 'pedagogueStudent', 'pedagogicalSpecialist'];
export const FOA_ROLES = ['pedagogicalAssistant', 'pa', 'pau', 'substitute'];
export const VERDI_ROLES = ['pedagogicalAssistant', 'chef', 'coordinator'];
export const OTHER_ROLES = ['kitchen', 'nutritionist', 'engineer', 'office'];

export const roleSections = [
    { label: 'BUPL', roles: BUPL_ROLES },
    { label: 'FOA', roles: FOA_ROLES },
    { label: 'Verdi', roles: VERDI_ROLES },
    { label: 'Andet', roles: OTHER_ROLES },
];
