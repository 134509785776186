import { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import { Link, Navigate, NavigateOptions, NavigateProps, Path, useNavigate } from 'react-router-dom';
import routes from '../config/routes.json';
import { EmployeeRole } from '../types/employee-role';

type Parameters = Record<string, string | number>;

export type Route = keyof typeof routes;

type CustomLinkComponent = { [X in `${Capitalize<string>}`]: (props: PropsWithChildren<any>) => JSX.Element | null };

type To = Route | (Omit<Partial<Path>, 'pathname'> & { pathname: Route });

const useMappedNavigation = () => {
    const navigate = useNavigate();
    return useCallback(
        (to: To, params?: Parameters, options?: NavigateOptions) => {
            typeof to === 'string'
                ? navigate(replaceRouteWithParams(routes[to], params), options)
                : navigate(
                      {
                          ...to,
                          pathname: replaceRouteWithParams(routes[to.pathname], params),
                      },
                      options
                  );
        },

        [navigate]
    );
};

export default useMappedNavigation;

type MappedLinkProps = { to: To; params?: Parameters };

const replaceRouteWithParams = (link: string, params: Parameters | undefined) => {
    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            link = link.replaceAll(`{${key}}`, String(value));
        });
    }

    return link;
};

export const MappedLink: FC<MappedLinkProps> = ({ to, params, children }) => {
    const linkTo =
        typeof to === 'string'
            ? replaceRouteWithParams(routes[to], params)
            : {
                  ...to,
                  pathname: replaceRouteWithParams(routes[to.pathname], params),
              };
    return <Link to={linkTo}>{children}</Link>;
};

type MappedNavigateProps = Omit<NavigateProps, 'to'> & MappedLinkProps;

export const MappedNavigate: FC<MappedNavigateProps> = ({ to, params, ...props }) => {
    const linkTo =
        typeof to === 'string'
            ? replaceRouteWithParams(routes[to], params)
            : {
                  ...to,
                  pathname: replaceRouteWithParams(routes[to.pathname], params),
              };
    return <Navigate to={linkTo} {...props} />;
};

export const CustomLink: CustomLinkComponent = {
    Planning: (props: { role: EmployeeRole; normWorkersSNO: number; children?: ReactNode }) => {
        let route;
        switch (props.role) {
            case 'manager':
            case 'pedagogue':
            case 'pedagogueStudent':
            case 'pedagogicalSpecialist':
            case 'pa':
            case 'pau':
            case 'chef':
            case 'coordinator':
            case 'kitchen':
            case 'nutritionist':
            case 'engineer':
            case 'office':
            case 'pedagogicalAssistant':
                route = routes['planning-worker'];
                break;
            case 'substitute':
                route = routes['planning-substitute'];
                break;
        }

        return (
            <Link to={replaceRouteWithParams(route, { normWorkersSNO: props.normWorkersSNO })}>
                {props.children ?? null}
            </Link>
        );
    },
};

export const appendHash = (hash?: string) => {
    if (!hash) {
        return '';
    }

    return `#${hash}`;
};
