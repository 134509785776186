/* eslint-disable @typescript-eslint/no-redeclare */
import { Literal, Static, Union } from 'runtypes';

const EMPLOYEE_ROLE_NAME_MAPPING: Record<EmployeeRole, string> = {
    manager: 'role.manager',
    pedagogue: 'role.pedagogue',
    pedagogueStudent: 'role.pedagogueStudent',
    pedagogicalSpecialist: 'role.pedagogicalSpecialist',

    pedagogicalAssistant: 'role.pedagogicalAssistant',
    pa: 'role.pa',
    pau: 'role.pau',
    substitute: 'role.substitute',

    chef: 'role.chef',
    coordinator: 'role.coordinator',

    kitchen: 'role.kitchen',
    nutritionist: 'role.nutritionist',
    engineer: 'role.engineer',
    office: 'role.office',
};

export const EmployeeRole = Union(
    Literal('manager'),
    Literal('pedagogue'),
    Literal('pedagogueStudent'),
    Literal('pedagogicalSpecialist'),

    Literal('pedagogicalAssistant'),
    Literal('pa'),
    Literal('pau'),
    Literal('substitute'),

    Literal('chef'),
    Literal('coordinator'),

    Literal('kitchen'),
    Literal('nutritionist'),
    Literal('engineer'),
    Literal('office')
);

export type EmployeeRole = Static<typeof EmployeeRole>;

export const employeeRoles = EmployeeRole.alternatives.map((alternative) => alternative.value);

export const getLabelByEmployeeRole = (role: EmployeeRole) => EMPLOYEE_ROLE_NAME_MAPPING[role];

export const getLabelByName = (role: string) => {
    try {
        return getLabelByEmployeeRole(EmployeeRole.check(role));
    } catch (e) {
        throw new Error(`Unknown employee role: ${e}`);
    }
};
